import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
//import crypto from 'crypto-js';
//import * as FaIcons from "react-icons/fa";
//import crypto from 'crypto';
import $ from 'jquery'
export function getDateD(separator=''){
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
    //return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}
export function getDateMA(separator=''){
    let newDate = new Date()
    //let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month<10?`0${month}`:`${month}`}${separator}${year}`;
    //return `${month<10?`0${month}`:`${month}`}${separator}${year}`
}
export function getDateYM(separator=''){
    let newDate = new Date()
    //let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month<10?`0${month}`:`${month}`}`;
    //return `${month<10?`0${month}`:`${month}`}${separator}${year}`
}
export function getDateA(){
    let newDate = new Date()
    //let date = newDate.getDate();
    //let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}`;
}

export function notify_valido(text){
    return toast.success(text,{
        className: "toast-message",
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

export function notify_warning(text){
    return toast.warn(text,{
        className: "toast-message",
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

export function notify_warning_fijo(text){
    return toast.warn(text,{
        className: "toast-message",
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        progress: undefined,
        // transition: Zoom,
      });
}

export function notify_error(text){
    return toast.error(text,{
        className: "toast-message",
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

//Titulo Texto/ 3fucniones para swall 'Pedido hecho satisfactoriamente!!' '¿Esta seguro de continuar?'

export function swall_warning(titulo,text){
    Swal.fire({
        title: titulo,
        text: text,
        icon: 'info',
        showDenyButton: true,
        denyButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#696cff",
        customClass: {
            container: 'my-swal'
          }

    }).then(async response =>  {
        if (response.isConfirmed) {
            Swal.fire({title:'PEDIDO HECHO!!!', text:'Descargando recibo', icon:'success',showConfirmButton:false, timer: 2000, customClass: {
                container: 'my-swal'}})
            setTimeout(() => {
                window.location.href = 'https://abohl360.alvarezbohl.pe/'; // redirigir a la página principal de abohl
            }, 2000);
            //FALTA hacer metodo para la descarga del pdf al darle "CONFIRMAR"
            
        }
        else if(response.isDenied){
            Swal.close();
        }
    })

}

export function swall_valido(titulo,text){
    Swal.fire({   
        title:titulo,
        text:text,
        icon:'success',
        showConfirmButton:false, 
        timer: 1000,
        customClass: {
            container: 'my-swal'
          }
        
});
}

export function swall_error(titulo,text){
    Swal.fire({   
        title: titulo,
        text: text,
        icon: 'error',
        showConfirmButton:false, 
        timer: 1500,
        customClass: {
            container: 'my-swal'
          }
});
}

export function selectTabla(text){
    $(document).on('click', '.'+text+' .table .rdt_TableRow .rdt_TableCell', function () {
        $('.'+text+' .table .rdt_TableRow').removeClass("table-danger");
        $($(this).parents('.'+text+' .table .rdt_TableRow')).addClass("table-danger");
    })
}

// export function fc_unsubscribe(cadena) {
//     //console.log(cadena);
//     const key = '1234567890abcdef1234567890abcdef';
//     var bytes  = crypto.DES.decrypt(cadena, key);
//     var decryptedData = bytes.toString(crypto.enc.Utf8);
//     //console.log(decryptedData); // 'my message'
//     return decryptedData;
// }
//const handleClick = (title) => {
    //console.log(`You clicked me! ${title}`);
  //};
