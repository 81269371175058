import React, { useState, useMemo, useEffect } from 'react'

import '../../App.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_valido, notify_error, notify_warning_fijo } from '../../components/utils';
import { Box, Modal, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Cookies from 'universal-cookie';
import moment from 'moment';

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const ImporReglas = () => {

  const [listaDatos, setListaDatos] = useState([])
  const [listaDatosEnvio, setListaDatosEnvio] = useState([])
  const [sorting, setSorting] = useState([]);
  const [pending, setpending] = useState(false);
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());

  const [porcentaje, setPorcentaje] = useState(0);
  const [verBarra, setVerBarra]= useState(false);

  const [filtro, setFiltro] = useState([]);

  /**
   * Funcion antigua
   */
  const fc_cargar_excel = async (event) => {
    setpending(true)
    const ideProveedor = "P&G"
    const timestamp = Date.now();
    const formData = new FormData();
    formData.append('xml', (event.target.files[0]));
    formData.append('ideProveedor', ideProveedor);
    formData.append('codEmpresa', cook_idEmp);
    const options = { method: "POST", headers: { 'Cache-Control': 'no-cache' }, body: formData }
    try {
      const response = await fetch('/apiSubirListarDatosExcel?t=' + timestamp, options);

      if (!response.ok) {
        throw new Error('Error al cargar los datos desde la API');
      }

      const resdata = await response.json();
      console.log("API Data: ", resdata);

      // Verificar si la respuesta contiene los datos esperados
      if (resdata && resdata.jsonData) {
        setListaDatos(resdata.jsonData);
        setListaDatosEnvio([resdata.enviados]);
      }
      // error en caso el archivo no este correcto
      if (resdata.errorGen) {
        notify_warning("" + resdata.messaje)
      }

      if (resdata.errorIdplan) {
        Swal.fire({
          title: 'Error',
          text: '' + resdata.messaje,
          icon: 'error',
          confirmButtonText: 'Cerrar',
          customClass: {
            popup: 'swal2-medium',
            icon: 'swal2-large',
            title: 'swal2-large',
          }
        });


        // notify_warning("" + resdata.messaje)
      }
      // else {
      //   throw new Error('La respuesta de la API no contiene los datos esperados');
      // }
    } catch (error) {
      // console.log("estoy en el cahthddjdj");
      notify_warning("Error al registrar, verificar datos!")
    } finally {
      setpending(false);
    }
  }

  // FUNCION    2
  const fc_estrutra_excel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    // Nombre de la Hoja
    const worksheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets['RD - AlvarezBohl'];
    if (sheet === undefined) {
      notify_warning("No se encontro la hoja ")
      return
    }

    const ref = sheet['!ref'];
    // console.log("ref:", ref);
    
    const rango = XLSX.utils.decode_range(ref);
    // console.log("rango:", ref);

    const rangeMod = `A1:${XLSX.utils.encode_col(rango.e.c)}${rango.e.r + 1}`
    // const rangeMod = `A1:${XLSX.utils.encode_col(rango.e.c)}${rango.e.r + 1}`
    console.log("rangeMod::", rangeMod);
    


    /** ============================================
     *      Validacion de la celda SUCURSAL
     * ============================================
     */

    // Dirección de la celda específica (AJ8)
    const cellAddress = 'AJ8'; // Aquí defines la celda que deseas calcular

    // Obtén el valor de la celda desde la hoja de cálculo
    const headerCell = sheet[cellAddress]; 
    if (!headerCell || typeof headerCell.v !== 'string') {
      // Si la celda no existe o no contiene texto, retorna un array vacío
      notify_warning_fijo("En la columna AJ debe estar los valores de las sucursales");

      return [];
    }
    // Obtén el texto almacenado y conviértelo a minúsculas
    const cellText = headerCell.v.trim().toLowerCase(); // Elimina espacios adicionales y convierte a minúsculas
    // Verifica si el texto no es "sucursal"
    if (cellText !== 'sucursal') {
      notify_warning_fijo("En la columna AJ debe estar los valores de las sucursales");
      return [];
    }



    const range = XLSX.utils.decode_range(rangeMod);
    const headerColumns = [];
    for (let col = range.s.c + 0; col <= range.e.c; col++) { // el +1 lleva a la columna B
      const columnName = XLSX.utils.encode_col(col); // Obtiene la letra de la columna
      headerColumns.push(columnName); // Usar el valor de la celda o la letra

      // console.log("Letra de la columna :: ", columnName );
      //  const headerCell = sheet[XLSX.utils.encode_cell({ r: range.s.r, c: col })];
      const headerCell = sheet[XLSX.utils.encode_cell({ r: 7, c: col })]; // ejmplo C1 - sheet[C1] -> combre de la celda
      // headerColumns.push(headerCell ? headerCell.v : columnName); // Usar el valor de la celda o la letra
    }
    console.log("headerColumns:: ", headerColumns);

    // const dataRange = `${XLSX.utils.encode_col(range.s.c)}${range.s.r + 1}:${XLSX.utils.encode_col(range.e.c)}${range.e.r + 1}`;
    // Rango de donde se empieza a recorrer los datos sin la cabecera
    // const dataRange = `A9:${XLSX.utils.encode_col(range.e.c)}${range.e.r + 1}`;
    // const dataRange = `A9:${XLSX.utils.encode_col(range.e.c)}${range.e.r + 1}`;
    const dataRange = `A9:AJ${range.e.r + 1}`;
    console.log("dataRange",dataRange);
    const arraycolumnas = ["B", "C", "D", "F", "G", "H", "I", "J", "K", "L",
      "M", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X",
      "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ"]
    const header2 = [
      "CodigoPlan",
      "IDPlan",
      "IO",
      "E",
      "Categoria",
      "Marca",
      "Bodegas",
      "Mercados",
      "Mayoristas",
      "TipoPlan",

      "PA_Producto",
      "PA_EAN",
      "N",
      "PA_Cantidad",
      "PA_UMedida",

      "PB_Cantidad",
      "PB_UMedida",
      "PB_EAN",
      "PB_Producto",

      "PC_Cantidad",
      "PC_UMedida",
      "PC_EAN",
      "PC_Producto",

      "PG_Cantidad",
      "PG_UMedida",
      "PG_EAN",
      "PG_Producto",

      "Descuento",
      "Max_POS",
      "Comentario_Sniper",
      "Fecha_Inicio",
      "Fecha_Fin",
      "Ven_Incluidos",
      "Ven_Excluidos",
      "Sucursal"
    ]

    // data Base
    let jsonDataBase = XLSX.utils.sheet_to_json(sheet, {
      range: dataRange,
      header: headerColumns,
      cellDates: false, // Para manejar fechas correctamente
      raw: false, // Para que aplique la función de limpiezas
      cellFormula: false, // Para que no se interpreten fórmulas
      cellHTML: false, // Para que no se interpreten HTML en las celdas
      cellText: false, // Para que no se interpreten datos de texto enriquecido
      cellNF: false, // Para que no se interpreten formatos de números
      cellStyles: false, // Para que no se interpreten estilos de celdas
      defval: '', // Valor por defecto para celdas vacías
      // dateNF: 'DD/MM/YYYY'
    });

    // Este vector es para las fecha correctamente
    // las configuraciones para leer el archivo cambian 
    let jsonData_fechas = XLSX.utils.sheet_to_json(sheet, {
      range: dataRange,
      header: headerColumns,
      cellDates: false, // Para manejar fechas correctamente
      raw: true, // Para que aplique la función de limpiezas
      cellFormula: false, // Para que no se interpreten fórmulas
      cellHTML: false, // Para que no se interpreten HTML en las celdas
      cellText: false, // Para que no se interpreten datos de texto enriquecido
      cellNF: false, // Para que no se interpreten formatos de números
      cellStyles: false, // Para que no se interpreten estilos de celdas
      defval: '', // Valor por defecto para celdas vacías
      // dateNF: 'DD/MM/YYYY'
    });

    console.log("jsonData::  ", jsonData_fechas);
    
    /**
     * Modificamos las fechas  con ayuda del vector anterior,
     * aqui se da el formato de texto en el orde de DD/MM/AA, tambien existe una validacion en el backend
     */
    jsonData_fechas.map((row, index) => {
      const date_AF = XLSX.SSF.parse_date_code(row.AF);
      const date_AG = XLSX.SSF.parse_date_code(row.AG);

      let row_AF = `${String(date_AF.d).padStart(2, '0')}/${String(date_AF.m).padStart(2, '0')}/${date_AF.y}`;
      let row_AG = `${String(date_AG.d).padStart(2, '0')}/${String(date_AG.m).padStart(2, '0')}/${date_AG.y}`;

      jsonDataBase[index].AF = row_AF;
      jsonDataBase[index].AG = row_AG;
      return { ...row, AF: row_AF, AG: row_AG }
    })
    console.log("jsonData CORREGIDO::  ", jsonDataBase);

    // return[];
    // let columInvalidas = '';
    // arraycolumnas.forEach((col) => {
    //   if (typeof jsonDataBase[0][col] === undefined) {
    //     columInvalidas += `${col}-`
    //   }
    // })
    // if (columInvalidas !== '') {
    //   console.log(columInvalidas);
    //   notify_error('ERROR::' + columInvalidas)
    // }
    return jsonDataBase;
  }

  // FUNCION    1
  const fc_Subir_Archivo = async (event) => {
    try {
      setpending(true)
      const ideProveedor = "P&G"
      const reader =   new FileReader();
      let data = [];
      const file = event.target.files[0];
      // const [dataExcel, setDataExcel] = useState({});
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const dataJson = fc_estrutra_excel(binaryStr);
        console.log(" Data de respuesta ",dataJson);
        
        validarDataJson(dataJson)
        fc_agruparJson(dataJson)
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Ocurrió un error en la línea:', error.lineNumber, error);
      console.log({ error: "/apiSubirListarDatosExcel -> " + error }, error.lineNumber);
      notify_error("Ocurrio un error al procesar el archivo")
    } finally {
      // notify_warning("TERMINO EL PROCESO ")
      console.log("Termino");
      setpending(false);
    }

  }

  // FUNCION   3
  /**
   * Esta funcion valida si la data del archivo es correcta 
   * @param {*} data 
   */
  const validarDataJson = (data) => {
    try {
      data.forEach((element, index) => {

        // console.log("Elemto  :: ",  );



      })
    } catch (error) {
      console.log("ERROR F::validarDataJson() ", error);
    } finally {
      console.log("validarDataJson");
    }

  }

  async function simulatedRequest(vec) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = { message: "Datos del servidor simulados" };
        // console.log("Vetores dentro ::", vec);

        resolve(data);
      }, 2000);
    });
  }
  
    const fc_agruparJson = async (data) => {
        let dataDeTabla = [];
        // data=[];
        let RESDescuentoPrecio = [];
        let RESBonificacionSoles = [];
        let RESBonificacionSimple = [];

        // let RESBonAgrupacionReglasCabecera  = [];
        let RESResumenBonAgruReglasHijos = [];
        let RESCombitoBonAgruReglasCabe = [];
        let RESDataExcel = [];

        try {
            setVerBarra(true);
            const tamaño = 30;// cantidad minima de cada bloque que se envia
            let posHasta = 0;
            let posActual = 0;
            let bloque = 0;
            const tamañoTotal = data.length;
            let StringEscala = "T6: Escala";
            StringEscala = StringEscala.replace(/\s+/g, '').toLocaleLowerCase();
            console.log("StringEscala:: ", StringEscala);
            setpending(true)
            // Este bloque de codigo valida que cuando se recorte un bloque  de la data 
            // no parta a  las escalas, si no que cada bloque se envie todas las escalas
            // NOTA:: Las escalas estan relacionadas
            while (posActual < data.length) {
                console.log(`==============================  INICIO BLOQUE ::: ${bloque} ==============================`);
                bloque++;
                posHasta = posActual + tamaño
                while ((posHasta < data.length) && (data[posHasta]?.["K"].replace(/\s+/g, '').toLocaleLowerCase() === StringEscala)) {
                    posHasta++;
                }
                let vector = data.slice(posActual, posHasta);
                //  vector = [];
                // console.log("Ventor :", vector);
                console.log(`Subvector de ${posActual} a ${posHasta}: diferencia ${posHasta - posActual}`);
                // nota para otro casos de rederisar el compon
                const diferencia = posHasta - posActual;
                const valorAumentar = parseInt((diferencia / tamañoTotal) * 100) || 0
                const options = {
                    method: "POST", headers:
                        { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' },
                    body: JSON.stringify({ "dataReglas": vector, "codEmpresa": cook_idEmp })
                }
                const response = await fetch('/apiSubirReglasExcel', options);

                if (!response.ok) {
                  console.log("Ocurrio un error::::",);
                  
                  notify_error("Ocurrio un error")
                    // throw new Error('Error al cargar los datos desde la API');
                }
                const resdata = await response.json();

                /** VALIDACION DE ERRORES */
                if (resdata.errorGen) {
                    notify_warning("" + resdata.messaje)
                }
                if (resdata.errorIdplan) {
                    Swal.fire({
                        title: 'Error',
                        text: '' + resdata.messaje,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        customClass: {
                        popup: 'swal2-medium',
                        icon: 'swal2-large',
                        title: 'swal2-large',
                        }
                    });
                }

                RESDescuentoPrecio.push(resdata.data.nuevoarrayDescuentoPrecio)
                RESBonificacionSoles.push(resdata.data.nuevoarrayBonificacionSoles)
                RESBonificacionSimple.push(resdata.data.nuevoarrayBonificacionSimple)
                RESResumenBonAgruReglasHijos.push(resdata.data.nuevoarrayCombitoBonAgruReglasHijos)
                RESCombitoBonAgruReglasCabe.push(resdata.data.nuevoarrayCombitoBonAgruReglasCabe)
                RESDataExcel.push(resdata.data.jsonData)
                console.log("API Data:: ", resdata);
                dataDeTabla.push(resdata.jsonData)
                setPorcentaje(((porcentaje) => (porcentaje + valorAumentar) > 100 ? 100 : (porcentaje + valorAumentar)));
                posActual = posHasta
            }
        } catch (error) {
            console.log("Ocurrio este error: ", error);

        } finally {
            setVerBarra(false)
            setpending(false)
            let result = [].concat(...dataDeTabla);
            setListaDatos(result)
            console.log("RESULTADO :: ", result);
            notify_valido("Termino el proceso ")
            console.log("==============================  DATA FINAL ==============================");
            let vecFinal = {
              RESDescuentoPrecio: [].concat(...RESDescuentoPrecio),
              RESBonificacionSoles: [].concat(...RESBonificacionSoles),
              RESBonificacionSimple: [].concat(...RESBonificacionSimple),
              RESResumenBonAgruReglasHijos: [].concat(...RESResumenBonAgruReglasHijos),
              RESCombitoBonAgruReglasCabe: [].concat(...RESCombitoBonAgruReglasCabe),
              RESDataExcel: [].concat(...RESDataExcel),
            }
            console.log("DATA EXCEL :: ", vecFinal);
        }
    }

  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "RB-AlvarezBohl"
    setPorcentaje(porcentaje + 10);
  }

  const lstSincronizado = useMemo(() => [
    'Sincronizado',
    'No sincronizado',
    'Error',
    'ID Plan Duplicado',
    'Sincronizado parcial',
    'Duplicado'
  ], []);

  const lstTipoDeBonificacion = useMemo(() => [
    'T0: Descuento a precio',
    'T1: Bonificación Simple',
    'T2: Bonificación por Soles',
    'T3: Combitos',
    'T4: Combazos',
    'T6: Escala',
  ], []);

  useEffect(() => {
    console.log("INICIO DE COMPONENTE ");
    function handleKeyDown(event) {
      // Verificar si se presionó la tecla Escape (código 27)
      if (event.keyCode === 27) {
        console.log('Se presionó la tecla Escape');
        setModalOpen(false);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  },[]);

  //*** funciones del modal  */

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowMouseDown = (event, row) => {
    console.log(row);
    setSelectedRow(row.original.arrayDetalleDeInserciones);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  /** fin de funciones de mdoal  */

  const BarProgress = ({ porcentaje, barPorgresVisible }) => {

    return (
      (barPorgresVisible &&
        <div className="card-body">
          {/* <div className="text-light small fw-medium">With Label</div> */}
          <div className="demo-vertical-spacing m-0">
            <div className="progress" style={{ "height": "15px" }} >
              <div className="progress-bar" role="progressbar" style={{ "width": `${porcentaje}%` }} aria-valuenow={`${porcentaje}`} aria-valuemin="10" aria-valuemax="100">{porcentaje}%</div>
            </div>
            {/* <div className="progress" style={{ "height": "16px" }}>
            <div className="progress-bar" role="progressbar" style={{ "width": "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">75%</div>
          </div> */}
          </div>
        </div>
      )
    )

  }

  const columns = useMemo(() => [

    {
      header: 'Periodo', accessorKey: 'PE',
      Cell: ({ row }) => {
        const formattedDate = moment(row.original.AF, 'DD-MM-YYYY').format('MM-YYYY');
        return <span>{formattedDate}</span>;
      }
    },
    { header: 'CODIGO DE PLAN', accessorKey: 'B', size: 200 },
    { header: 'ID DE PLAN', accessorKey: 'C' },
    {
      header: 'Estado de regla', accessorKey: 'ZZ', filterVariant: 'multi-select', filterSelectOptions: lstSincronizado,
      muiTableBodyCellProps: ({ row, cell }) => {
        const valor = row.original.ZZ
        let backgroundColor = "";
        if (valor === "Sincronizado") {
          backgroundColor = '#91F77A'
        } else if (valor === "Error") {
          backgroundColor = '#E91200'
        } else {
          backgroundColor = '#F7921E'
        }
        return {
          sx: { backgroundColor }
        }
      }
      // [
      //   "Sincronizado",
      //   "No Sincronizado"
      //   // { text: 'Ocurrió un error', value: 'Ocurrió un error' },
      // ],

    },
    // {header: 'IO', accessorKey: 'D' },
    // {header: '', accessorKey: 'E' },
    { header: 'CATEGORIA', accessorKey: 'F' },
    { header: 'MARCA', accessorKey: 'G' },
    { header: 'BODEGAS', accessorKey: 'H', enableColumnFilter: false, size: 140, },
    { header: 'MERCADOS', accessorKey: 'I', enableColumnFilter: false, size: 150, },
    { header: 'MAYORISTAS', accessorKey: 'J', enableColumnFilter: false, size: 160, },


    {
      header: 'TIPO PLAN', accessorKey: 'K', filterVariant: 'multi-select', filterSelectOptions:
        lstTipoDeBonificacion
    },
    // producto A
    { header: 'PRODUCTO_A', accessorKey: 'L' },
    { header: 'EAN_A', accessorKey: 'M' },
    // {header: '', accessorKey: 'N' },
    { header: 'CANTIDAD_A', accessorKey: 'O' },
    { header: 'UNIDAD MEDIDA_A', accessorKey: 'P' },
    // producto B
    { header: 'CANTIDAD_B', accessorKey: 'Q' },
    { header: 'UNIDAD MEDIDA_B', accessorKey: 'R' },
    { header: 'EAN BONIFICADO_B', accessorKey: 'S', size: 200, },
    { header: 'PRODUCTO_B', accessorKey: 'T' },
    // producto C 
    { header: 'CANTIDAD_C', accessorKey: 'U' },
    { header: 'UNIDAD DE MEDIDA_C', accessorKey: 'V', size: 200, },
    { header: 'EAN MODIFICADO_C', accessorKey: 'W', size: 200, },
    { header: 'PRODUCTO_C', accessorKey: 'X' },
    // producto Bonificado 
    { header: 'CANTIDAD_D', accessorKey: 'Y' },
    { header: 'UNIDAD DE MEDIDA_D', accessorKey: 'Z', size: 200, },
    { header: 'EAN BONIFICADO_D', accessorKey: 'AA', size: 200, },
    { header: 'PRODUCTO_D', accessorKey: 'AB' },
    // detalles
    { header: '% Dsct.', accessorKey: 'AC' },
    { header: 'Max x POS?', accessorKey: 'AD' },
    { header: 'Comentario', accessorKey: 'AE' },
    { header: 'FECHA INICIO', accessorKey: 'AF' },
    { header: 'FECHA FIN', accessorKey: 'AG', filterFn: 'equals', },
    { header: 'Ven Incluido', accessorKey: 'AH', filterFn: 'equals', },
    { header: 'Ven Excluido', accessorKey: 'AI', filterFn: 'equals', },
    { header: 'Sucursal', accessorKey: 'AJ' },
    // {header: 'Costo de la promoción', accessorKey: 'AH' },
    // {header: '¿Esta promo está alineada con los RBU promo guidelines?', accessorKey: 'AI' },
    // {header: 'Si la respuesta es no, ¿hay aprobación del RBU?', accessorKey: 'AJ',},

  ], [lstSincronizado, lstTipoDeBonificacion],);

  async function listarHistorico() {
    setpending(true)
    const periodo = moment(inp_text_fecha, 'DD/MM/YYYY').format('YYYY-MM')
    console.log("perido: ", periodo);
    // formData.append('codEmpresa', cook_idEmp);
    const options = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ periodo: periodo }) }
    try {
      const response = await fetch('/lisHistorico?periodo=' + periodo, options);
      // console.log("API Data: ", response);
      if (!response.ok) {
        throw new Error('Error al cargar los datos desde la API');
      }

      const resdata = await response.json();
      console.log("API Data: ", resdata);

      // Verificar si la respuesta contiene los datos esperados
      if (resdata.estado === 1) {
        const dataFiltro = [];
        setListaDatos(resdata.data);
        resdata.data.map(element => {
          dataFiltro.push(element.ZZ);
        })
        // console.log(dataFiltro);
        const arraySinRepetidos = [...new Set(dataFiltro)];
        // console.log("arraySinRepetidos:: ", arraySinRepetidos);
        // setFiltro(arraySinRepetidos)
        // setFiltro((arraySinRepetidos)=>{
        //    return arraySinRepetidos
        // })

      } else if (resdata.estado === 2) {
        setListaDatos([]);
        notify_warning("No se  encontraron datos !")
      } else {
        setListaDatos([]);
        notify_warning("Error en la consulta verificar datos!")// Manejo de errores del servidor
        return;
      }
      // if (resdata.errorGen) {
      //   notify_warning("" + resdata.messaje)
      // }

      // if (resdata.errorIdplan) {
      //   Swal.fire({
      //     title: 'Error',
      //     text: ''+resdata.messaje,
      //     icon: 'error',
      //     confirmButtonText: 'Cerrar',
      //     customClass: {
      //       popup: 'swal2-medium',
      //       icon: 'swal2-large',
      //       title: 'swal2-large',
      //     }
      //   });
      // }


      // notify_warning("" + resdata.messaje)
      // else {
      //   throw new Error('La respuesta de la API no contiene los datos esperados');
      // }
    } catch (error) {
      setListaDatos([]);
      // console.log("estoy en el cahthddjdj");
      notify_warning("Error en la consulta verificar datos!")
    } finally {
      setpending(false);
    }
  }
  const estiloTabla = {
    borderRadius: '10px', // Ajusta el valor según el grado de redondeo que desees
    // Agrega cualquier otro estilo necesario
  };
  return (
    <div>

      <h6 className="text-muted">RB - AlvarezBohl - Davin</h6>
      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalToggle1">Abrir Modal</button> */}
      <div className="card ">
        <div className="card-header ">
          {/* <button type="button" className="btn btn-success d-none" onClick={Guardar}> */}
          {/* <i className="tf-icons bx bxs-cloud-download" />
          </button>  */}
          <div className="row">
            <div className="col-lg-2">
              <label htmlFor="formFile" className="btn btn-danger btn-sm">
                <i className="tf-icons bx bx-upload" /> Subir Documento
              </label>
              <input type="file" id="formFile" name="formFile" accept='.csv,.xlsx,.xlsm' className="form-control form-control-sm d-none" onChange={fc_Subir_Archivo} />
            </div>

            <div className="col-lg-1">
              Periodo:
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={inp_text_fecha}
                onChange={date => setInp_text_fecha(date)}
                dateFormat="MM/yyyy"  // Muestra solo el mes y el año
                showMonthYearPicker  // Permite seleccionar mes y año directamente
              />
            </div>

            <div className="col-lg-2">
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={listarHistorico} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                &nbsp;Consultar
              </button>
            </div>

            <div className="col-lg-4">
              <div className="row justify-content-end">
                <div className="col-auto">
                  <button type="button" className="btn btn-success btn-sm" onClick={onClickExcel}>
                    <i className="tf-icons bx bxs-cloud-download" /> Descargar Reporte
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* <a href={"/archivos/image.pmg"} target="_blank" rel="noreferrer">holaaa</a> */}


        <div className="card-body">  {/* <div className="card-body">  */}
          <BarProgress porcentaje={porcentaje} barPorgresVisible={verBarra} />  

          <MaterialReactTable
            columns={columns}
            data={listaDatos}
            className={estiloTabla}
            enableColumnResizing
            enableGrouping
            enableStickyHeader
            onSortingChange={setSorting}
            state={{ isLoading: pending, sorting }}
            initialState={{ showColumnFilters: false, density: 'compact', expanded: true, showGlobalFilter: false }} //filtro de columnas y toggle pequeño
            enableStickyFooter
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            enablePagination={true}
            enableColumnActions={false}
            localization={MRT_Localization_ES}
            muiTableContainerProps={{ sx: { maxHeight: 450 } }}
            muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
            enableGlobalFilterModes
            rowVirtualizerProps={{ overscan: 8 }}
            enableRowVirtualization
            enableRowActions
            renderRowActions={({ row }) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                {/* bt_funct={() => onClickModalDetalle(row)} */}
                <AButton tp_tilde="Mas detalle" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-success detallePedido" bt_funct={(event) => handleRowMouseDown(event, row)} ic_class="bx bx-ms bx-show" vl_tpbtn={1} />

              </Box>
            )}
          // muiTableBodyRowProps={({ row, event }) => ({
          //   onMouseDown: (event) => handleRowMouseDown(event, row)
          // })}
          />
        </div>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasRightLabel">Envios</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T0: Descuentos de precios</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.descuentoPrecio?.sincronizados?.length}</span> Envios correctos </div>
                {/* <button className='btn btn-sm btn-primary'>Detalles</button> */}
                <span>
                </span>
                <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.descuentoPrecio.error.length}</span> Envios fallidos </p>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T1: Bonificacion simple</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSimple?.sincronizados?.length}</span> Envios correctos</div>
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSimple.error.length}</span> Envios fallidos</p>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T2: Bonificacion por soles</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSoles?.sincronizados?.length}</span> Envios correctos</div>
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSoles.error.length}</span> Envios fallidos</p>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T6: Escala</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.escala?.sincronizados?.length}</span> Envios correctos</div>
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.escala.error.length}</span> Envios fallidos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <ModalDetalleFila
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
      />

    </div>

  )
}

export default ImporReglas

function ModalDetalleFila({ modalOpen, handleCloseModal, selectedRow }) {
  return (
    <div className={` modal fade  ${modalOpen ? 'show' : ''}`} id="modalToggle1" tabIndex="-1" style={{ display: modalOpen ? 'block' : 'none' }}>
      <div className="modal-dialog modal-lg ">
        <div className="modal-content  ">
          <div className="modal-header">
            <h5 className="modal-title ">Detalle de sincronización</h5>
            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {selectedRow ? (
              <div>
                <div className="row mb-1">
                  <div className="col mb-0">
                    <label className="form-label">ID PLAN:</label>
                    <span className="form-control" aria-readonly="true">{selectedRow.idPlan}</span>
                  </div>
                  <div className="col mb-0">
                    <label className="form-label">ESTADO:</label>
                    <span className="form-control" aria-readonly="true">{selectedRow.estado}</span>
                  </div>
                </div>
                <label className="form-label">MENSAJE:</label>
                {/* <div className="form-control" aria-readonly="true" style={{ whiteSpace: 'pre-wrap' }}> */}
                <div className="form-control" aria-readonly="true" style={{ whiteSpace: 'pre-wrap', overflowY: 'auto', maxHeight: '370px' }}>
                  {/* <pre className="text-primary">{selectedRow.mensaje}</pre> */}
                  {selectedRow.mensaje.split('\r').map((line, index) => {
                    let modifiedLine = line;
                    if (line.includes("**")) {
                      modifiedLine = line.replace(/\*\*/g, '🟢'); // Reemplazar ** por círculo verde
                    } else if (line.includes("##")) {
                      modifiedLine = line.replace(/##/g, '🟠'); // Reemplazar ## por círculo rojo
                    }
                    return (
                      <p style={{ marginBottom: '0px', fontSize: '13px' }} key={`${line}_${index}`}>
                        {modifiedLine}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <p className="text-primary">El IDPlan de la fila no está definido</p>
              </div>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cerrar</button>
            {/* Agrega aquí los botones u opciones adicionales del pie del modal si es necesario */}
          </div>
        </div>
      </div>
    </div>
  );
}
